import React from 'react'
import { Container } from './styles'
import Button from '@veneer/core/dist/scripts/button'

function ErrorInformationCard({ getText }) {
  const handleOnClick = () => {
    window.location.reload()
  }
  return (
    <Container data-testid="error-information-screen">
      <p className="title">{getText('error.generic.title')}</p>
      <p className="subtitle">{getText('error.generic.subtitle')}</p>
      <Button
        className="reload-button"
        data-testid="reload-button"
        appearance="secondary"
        onClick={handleOnClick}
      >
        {getText('error.generic.action')}
      </Button>
    </Container>
  )
}
export default ErrorInformationCard
