import { IconDownload } from '@veneer/core/dist/scripts/icons'
import React, { useEffect } from 'react'
import { publishEvent, StatementsDownloadLinkClicked } from '@/utils/analytics'
import { Container } from './styles'
import { Statement } from 'src/types/Statements'

import getStatementDownloadLink from '@/helpers/getStatementDownloadLink'
import { jWebReady } from '@jarvis/jweb-core'

type StatementDownloadLinkProps = {
  statement: Statement
  showDownloadErrorNotification: () => void
}
export default function StatementDownloadLink({
  statement,
  showDownloadErrorNotification
}: StatementDownloadLinkProps) {
  const [isInWebview, setIsInWebview] = React.useState(false)

  useEffect(() => {
    const checkWebview = async () => {
      const jweb = await jWebReady

      setIsInWebview(
        jweb?.isNative ||
          jweb?.platform !== 'web' ||
          navigator?.userAgent?.includes('WebView')
      )
    }

    checkWebview()
  }, [])

  return (
    <Container>
      {isInWebview ? (
        <a
          href={window?.location?.href ?? process.env.BASE_URL}
          target="_blank"
          rel="noreferrer"
          data-testid="statement-dl-link-data-a-tag"
          onClick={() => {
            publishEvent(StatementsDownloadLinkClicked)
          }}
        >
          <IconDownload />
        </a>
      ) : (
        <div
          data-testid="statement-dl-link-data"
          onClick={async () => {
            try {
              console.log('DownloadLinkCheck1')
              const pdfWindow = window.open()
              if (!pdfWindow) {
                throw new Error('Failed to open new window.')
              }

              const downloadLink = await getStatementDownloadLink(statement)
              if (!downloadLink) {
                throw new Error('No download link returned.')
              }

              publishEvent(StatementsDownloadLinkClicked)

              const documentTitle =
                downloadLink.filename || downloadLink.message
              pdfWindow.document.write(
                `<iframe id="${documentTitle}" type="application/pdf" width="100%" height="100%" style="border-width: 0" src="data:${downloadLink.data}"></iframe>`
              )
              pdfWindow.document.body.style.margin = '0'
              pdfWindow.document.title = documentTitle
            } catch (error) {
              console.error(JSON.stringify(error ?? ''))
              showDownloadErrorNotification()
            }
          }}
        >
          <IconDownload />
        </div>
      )}
    </Container>
  )
}
