import useStatementsClient from '../useStatementsClient'
import useDataFetcher from '../useDataFetcher'

const useStatements = (params) => {
  const statementsClient = useStatementsClient()
  return useDataFetcher(
    'statements',
    /* istanbul ignore next */
    statementsClient?.getStatements
      ? statementsClient.getStatements.bind(statementsClient, params)
      : null
  )
}

export default useStatements
