import { Statement } from '@/types/Statements'

export function filterAndOrderData(
  statements: Statement[],
  fromDate: Date,
  toDate: Date
) {
  return statements
    ?.filter((statement) => {
      const invoiceDate = new Date(statement.invoiceDate)
      return fromDate <= invoiceDate && invoiceDate <= toDate
    })
    .sort(
      (st1, st2) =>
        new Date(st2.invoiceDate).getTime() -
        new Date(st1.invoiceDate).getTime()
    )
}
