import StatementsClient from '@/services/StatementsClient'
import useAccountMgtSvcClient from '../useAccountMgtSvcClient'

const statementsClientRef = { current: null }

export default function useStatementsClient() {
  const accountMgtSvcClient = useAccountMgtSvcClient()
  if (!statementsClientRef.current)
    statementsClientRef.current = new StatementsClient(accountMgtSvcClient)

  return statementsClientRef.current
}
