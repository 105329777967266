import React, { memo } from 'react'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'
import useGetText from '@/hooks/useGetText'

import { StyledInlineNotificationContainer } from './styles'

function InvoiceDownloadErrorNotification() {
  const getText = useGetText('statements.error')
  return (
    <>
      <StyledInlineNotificationContainer>
        <InlineNotification
          closeButton={false}
          title={getText('invoiceError.message', {
            defaultValue:
              'The PDF invoice could not be generated. Please try again later.'
          })}
          type="negative"
        />
      </StyledInlineNotificationContainer>
    </>
  )
}
export default memo(InvoiceDownloadErrorNotification)
