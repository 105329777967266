import Select from '@veneer/core/dist/scripts/select'
import Table from '@veneer/core/dist/scripts/table'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const breakPoints = {
  xl: '(min-width: 1738px)',
  lg: '(min-width: 1008px)',
  md: '(min-width: 1007px)',
  sm: '(min-width: 640px)',
  maxXl: '(max-width: 1738px)',
  maxLg: '(max-width: 1008px)',
  maxMd: '(max-width: 1007px)',
  maxSm: '(max-width: 640px)'
}

export const StyledBaseContainer = styled.div`
  margin: ${tokens.typography.size9};
  font-family: ${tokens.typography.family0};
`

export const StyledParagraph = styled.p`
  margin-bottom: ${tokens.typography.size9};
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  font-weight: 400;
  color: ${tokens.color.gray12};
`

export const StyledTitle = styled.h1`
  font-family: ${tokens.typography.family0};
  margin-bottom: ${tokens.layout.size2};
  font-weight: 400;
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight7};
  color: ${tokens.color.gray12};
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;
  > div {
    border-radius: ${tokens.layout.size3};
    border-color: ${tokens.color.gray2};
  }
  th {
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.lineHeight1};
    padding: 10px ${tokens.layout.size2};
  }
  th#description {
    pointer-events: none;
  }
  td {
    line-height: ${tokens.typography.lineHeight3};
    padding: ${tokens.layout.size3} ${tokens.layout.size2};
  }
  & > div > div > div {
    flex: 1;
  }
  tbody > tr > td > div > div > svg {
    fill: ${tokens.color.hpBlue7};
  }
  tbody>tr: nth-of-type(2n+1) {
    background-color: ${tokens.color.white};
  }
  tbody>tr: nth-of-type(2n) {
    background-color: ${tokens.color.gray1};
  }
  nav > ul > li > button:not(:disabled, .next, .previous)[aria-current='true'] {
    background-color: ${tokens.color.hpBlue7};
  }
`
export const StyledInlineNotificationContainer = styled.div`
  > div {
    border-radius: 0;
  }
  span {
    font-weight: 700;
  }
`
export const StyledActionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.layout.size8};
  justify-content: space-between;
  margin-bottom: ${tokens.typography.size9};
  width: 100%;
  @media ${breakPoints.sm} {
    flex-direction: row;
  }
`

export const StyledSelect = styled(Select)`
  max-width: 272px;
  height: ${tokens.typography.size8};
  & > div {
    height: ${tokens.typography.size8};
    border-radius: ${tokens.layout.size2};
    border-color: ${tokens.color.gray7};
  }

  & > div > div {
    line-height: ${tokens.typography.lineHeight2};
  }
  span {
    color: ${tokens.color.gray9};
  }
  svg {
    fill: ${tokens.color.gray7} !important;
  }
  @media ${breakPoints.maxSm} {
    max-width: 100%;
  }
`
