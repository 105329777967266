import getStratusToken from '@/helpers/getStratusToken'
import axios from 'axios'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import type { Statement, StatementDownloadLink } from '@/types/Statements'

interface DownloadLink {
  data: string
  message: string
  filename: string
  link: string
}

enum EndpointConstants {
  REFUND = 'refund',
  INVOICES = 'invoices',
  BEARER = 'Bearer '
}

const constructDownloadURL = (statement: Statement): string => {
  const purchaseTypes = [
    'Purchase',
    'Purchase_Cancellation',
    'purchase_cancellation_refund',
    'purchase_refund'
  ]

  const base = `${process.env.COMMERCE_MANAGEMENT_URL}/${
    purchaseTypes.includes(statement?.resourceType) ? 'v2/purchases' : 'orders'
  }`

  return statement.resourceType.includes(EndpointConstants.REFUND)
    ? `${base}/${statement.parentResourceId}/${EndpointConstants.INVOICES}?TYPE=REFUNDS`
    : `${base}/${statement.resourceId}/${EndpointConstants.INVOICES}`
}

export default async function getStatementDownloadLink(
  statement: Statement
): Promise<DownloadLink | undefined> {
  try {
    const stratusToken = await getStratusToken()
    const downloadURL = constructDownloadURL(statement)

    const { data } = await axios.get(downloadURL, {
      headers: {
        authorization: `${EndpointConstants.BEARER}${stratusToken}`
      }
    })

    return statement.resourceType.includes(EndpointConstants.REFUND)
      ? (data?.invoiceList?.find(
          (item: StatementDownloadLink) =>
            item.refundResourceId === statement.resourceId
        ) as DownloadLink)
      : (data?.invoiceList?.[0] as DownloadLink)
  } catch (error) {
    if (error.response?.status === 403) {
      forceLoginHandling()
    } else {
      throw error
    }
  }
}
