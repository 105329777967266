import { useCallback } from 'react'
import useAssetsProvider from '../useAssetsProvider'

const useGetText = (prefixKey?: string) => {
  const assetsProvider = useAssetsProvider()

  return useCallback(
    (subkey?: string, keys?: Record<string, string>) => {
      const key = [prefixKey, subkey].filter((e) => e).join('.')
      /* istanbul ignore next */

      if (keys && 'defaultValue' in keys) {
        return assetsProvider?.getText(key, keys) !== key
          ? assetsProvider?.getText(key, keys)
          : keys['defaultValue']
      } else {
        return assetsProvider?.getText(key, keys) || key
      }
    },
    [assetsProvider, prefixKey]
  )
}
export default useGetText
