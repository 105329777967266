import axios, { AxiosError } from 'axios'
import getStratusToken from '@/helpers/getStratusToken'
import InfoDatas from 'src/types/InfoDatas'
import { getNavigatorLanguage } from '@/helpers/getLocation'
import type Customer from '@/types/Customer'
import type {
  Statement,
  GetCommerceManagementStatementsType
} from '@/types/Statements'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import { prepareStatements } from '../../utils/prepareStatements'
import { IICreditNoteType, InstantInkType } from 'src/types/InstantInkStatement'
import { HeadersType } from 'src/types/Headers'
import toDateObject from '@/utils/toDate'
import { filterAndOrderData } from '@/utils/filterAndOrderData'
import { trace } from '@opentelemetry/api'

type AccountClient = Pick<AccountMgtSvcClient, 'getAccount'>
export default class StatementsClient {
  private accountClient: AccountClient
  constructor(accountClient: AccountClient) {
    axios.defaults.params = {}
    axios.defaults.params['locale'] = getNavigatorLanguage()
    this.accountClient = accountClient
  }

  mergeStatements(
    commerceManagementStatements: Statement[],
    InstantInkStatements: Statement[]
  ) {
    return commerceManagementStatements
      ? InstantInkStatements.concat(commerceManagementStatements)
      : InstantInkStatements
  }

  async getStatements(
    props: Record<string, string>
  ): Promise<InfoDatas['statements'] | Statement[]> {
    const { fromDate, toDate } = props
    const stratusToken = await getStratusToken()
    const headers = {
      authorization: `Bearer ${stratusToken}`
    }
    /* istanbul ignore next */
    const InstantInkStatements = this.getInstantInkStatements(headers)
    const commerceManagementStatements = this.getCommerceManagementStatements({
      headers,
      fromDate,
      toDate
    })

    const allStatements = Promise.all([
      InstantInkStatements,
      commerceManagementStatements
    ]).then((values) => {
      const InstantInkStatements = values[0]
      const commerceManagementStatements = values[1]
      const mergedStatements = this.mergeStatements(
        commerceManagementStatements,
        InstantInkStatements
      )

      const filteredData = filterAndOrderData(
        mergedStatements,
        toDateObject(fromDate),
        toDateObject(toDate, 1)
      )
      return filteredData
    })
    return allStatements
  }

  async getCommerceManagementStatements({
    headers,
    fromDate,
    toDate
  }: GetCommerceManagementStatementsType) {
    try {
      const {
        data: { resourceId: tenant_id }
      } = await this.accountClient.getAccount()
      const { resourceId } = await this.getCustomerId(tenant_id)
      const { data: statements } = await axios.get<InfoDatas['statements']>(
        `${process.env.COMMERCE_MANAGEMENT_URL}/customers/${resourceId}/invoice-data`,
        {
          headers,
          params: {
            fromDate,
            toDate
          }
        }
      )
      return statements
    } catch (e) {
      /* istanbul ignore next */
      this.statementsErrorHandling(e, 'commerce management')
    }
  }

  statementsErrorHandling = (error: AxiosError, type?: string) => {
    const status = error?.response?.status
    if (status === 404) {
      return Promise.resolve(null)
    }
    const span = trace.getTracer('invoices').startSpan('invoices', {
      attributes: {
        'workflow.name': 'monetization/invoices-management.statements.error',
        error: true,
        'error.message':
          `Error while fetching statement data from ${type} client: ` +
          JSON.stringify(error)
      }
    })
    span.end()
    return Promise.reject(error)
  }

  async getInstantInkStatements(headers: HeadersType): Promise<Statement[]> {
    const invoice = axios
      .get<Array<InstantInkType>>(`${process.env.COMFE_URL}/tenant/invoice`, {
        headers
      })
      .catch((error) => {
        return this.statementsErrorHandling(error, 'instant ink')
      })
    const creditNote = axios
      .get<Array<IICreditNoteType>>(
        `${process.env.COMFE_URL}/tenant/credit_note`,
        {
          headers
        }
      )
      .catch((error) => {
        return this.statementsErrorHandling(error, 'instant ink')
      })

    const oneTimePurchase = axios
      .get<Array<InstantInkType>>(
        `${process.env.COMFE_URL}/tenant/one_time_purchase`,
        {
          headers
        }
      )
      .catch((error) => {
        return this.statementsErrorHandling(error, 'instant ink')
      })

    const instantInkStatements = Promise.all([
      invoice,
      creditNote,
      oneTimePurchase
    ]).then((values) => {
      const { data: invoices } = values?.[0] || {
        data: new Array<InstantInkType>()
      }
      const { data: creditNotes } = values?.[1] || {
        data: new Array<IICreditNoteType>()
      }
      const { data: oneTimePurchase } = values?.[2] || {
        data: new Array<InstantInkType>()
      }

      let allInstantInkStatements = []

      if (creditNotes?.length > 0) {
        const creditNoteList = creditNotes.reduce((acc, invoice) => {
          const { credit_notes: creditNotesArray } = invoice
          return [...acc, ...creditNotesArray]
        }, [])
        const prepareCreditNote = prepareStatements(creditNoteList)
        allInstantInkStatements =
          allInstantInkStatements.concat(prepareCreditNote)
      }

      if (invoices?.length > 0) {
        const prepareInvoiceList = prepareStatements(invoices)
        allInstantInkStatements =
          allInstantInkStatements.concat(prepareInvoiceList)
      }
      if (oneTimePurchase?.length > 0) {
        const prepareOneTime = prepareStatements(oneTimePurchase)
        allInstantInkStatements = allInstantInkStatements.concat(prepareOneTime)
      }
      return allInstantInkStatements
    })
    return instantInkStatements
  }

  async getCustomerId(tenantId: string): Promise<Customer> {
    const stratusToken = await getStratusToken()
    const customer = await axios
      .get<Customer>(`${process.env.COMMERCE_MANAGEMENT_URL}/customers`, {
        headers: {
          authorization: `Bearer ${stratusToken}`
        },
        params: {
          userTenantId: tenantId
        }
      })
      .then((customer) => customer.data)
      .catch((error) => {
        return this.statementsErrorHandling(error, 'getCustomerId')
      })
    return customer
  }
}
