import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Container = styled.div`
  cursor: pointer;
  svg {
    width: ${tokens.typography.size6};
    height: ${tokens.typography.size6};
    fill: #0278ab;
  }
`
